<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 col-xl-8">

        <!-- Header -->
        <div class="header mt-md-5">
          <div class="header-body">
            <h6 class="header-pretitle">
              Inicio
            </h6>
            <h1 class="header-title">
              ¡Hola {{$store.state.userData.name}}!
            </h1>
          </div>
        </div>

        <h2 class="d-inline-block"><span class="badge bg-primary-soft mt-n1">v1.6.3</span></h2> <span class="text-muted"> – 10 septiembre, 2023</span>

        <h2 class="d-inline-block"><span class="badge bg-primary-soft mt-n1">v1.6.2</span></h2> <span class="text-muted"> – 26 junio, 2023</span>
        <br />
        <h2 class="d-inline-block"><span class="badge bg-primary-soft mt-n1">v1.5</span></h2> <span class="text-muted"> – 22 marzo, 2022</span>

        <template v-if="showIfContains('bycyoga')">
          <p>
            <strong>BYCYOGA</strong>
          </p>

          <ul class="mb-5">
            <li>Ahora se puede extender la validez de un saldo (se agregan 10 días).</li>
            <li>Reporte de alumnos entrega vencimientos de saldos.</li>
          </ul>
        </template>
        <br />

        <h2 class="d-inline-block"><span class="badge bg-primary-soft mt-n1">v1.4</span></h2> <span class="text-muted"> – 2 marzo, 2022</span>

        <template v-if="showIfContains('bycyoga')">
          <p>
            <strong>BYCYOGA</strong>
          </p>

          <ul class="mb-5">
            <li>Se desactiva mensaje al inicio</li>
            <li>Ahora se pueden comprar membresías (Plus y Básica)</li>
            <li>Listo para activar compra de clases por Zoom</li>
          </ul>
        </template>
        <br />

        <h2 class="d-inline-block"><span class="badge bg-primary-soft mt-n1">v1.3.2</span></h2> <span class="text-muted"> – 9 febrero, 2022</span>

        <template v-if="showIfContains('bycyoga')">
          <p>
            <strong>BYCYOGA</strong>
          </p>

          <ul class="mb-5">
            <li>Reportería arreglada</li>
            <li>Se agrega selección de fecha para reporte de alumnos</li>
            <li>Otros arreglos menores para manejo de clases</li>
            <li>Primera versión del reporte de listado de alumnos</li>
          </ul>

          <ul class="mb-5">
            <li>Luego de detectarse que existen muchos alumnos que compran dos veces el mismo box, se decide filtrar esta información y solo considerar uno para evitar inconvenientes</li>
          </ul>
        </template>
        <br />

        <h2 class="d-inline-block"><span class="badge bg-primary-soft mt-n1">v1.3</span></h2> <span class="text-muted"> – 27 enero, 2022</span>

        <template v-if="showIfContains('bycyoga')">
          <p>
            <strong>BYCYOGA</strong>
          </p>

          <ul class="mb-5">
            <li>Preparando aplicación para poder ocupar Zoom.</li>
            <li>Listado de alumnos que se inscriben por zoom y listado de emails</li>
            <li>Botón para generar las clases ahora está en la sección `generar clases`</li>
          </ul>
        </template>
        <br />

        <h2 class="d-inline-block"><span class="badge bg-primary-soft mt-n1">v1.2.1</span></h2> <span class="text-muted"> – 10 enero, 2022</span>

        <template v-if="showIfContains('bycyoga')">
          <p>
            <strong>BYCYOGA</strong>
          </p>

          <ul class="mb-5">
            <li>Mejoras importantes en la usabilidad en transacciones manuales (búsqueda de alumnos) y selección de datos</li>
            <li>Mejoras importantes en la usabilidad clases </li>
            <li>Preparando versión que permitirá aceptar Términos y Condiciones y luego enviarlos por correo</li>
          </ul>
        </template>
        <br />

        <h2 class="d-inline-block"><span class="badge bg-primary-soft mt-n1">v1.2.0</span></h2> <span class="text-muted"> – 6 enero, 2022</span>

        <template v-if="showIfContains('bycyoga')">
          <ul class="mb-5">
            <li><span class="badge bg-warning ms-auto">mejora importante</span> Ahora se envía un correo luego de una compra, con el resumen de las clases compradas. Si compra usando Webpay además le llegará otro correo con los datos de la transacción (pago)</li>
            <li>Mejora en buscador de alumno en transacciones manuales (busca por rut, email, teléfono, etc)</li>
            <li>Idem para buscador de alumnos</li>
            <li>Se agrega campo email a los reportes</li>
            <li>Ya no es posible pasar la lista en las clases "generadas" solo será posible hacerlo en las <a href="/apps/bycyoga/clase-profesor" class="nav-linkQ">clases "simples" (click acá para ir)</a> de tal forma de centralizarlo en los profesores (los admin también pueden pasar lista si entran a esa opción)</li>
            <li>Ahora solo se muestran las clases de hasta 24 horas en el pasado, con el fin de que se pueda pasar lista al salir de la clase</li>
            <li>Se muestra el vencimiento de los saldos de un alumno</li>
            <li>Se muestra el listado de clases del alumno en su "ficha"</li>
          </ul>

          <p>
            <strong>BYCYOGA</strong>
          </p>
          <ul class="mb-5">
            <li>Funcionalidad de logout implementada</li>
            <li>Cambio del color de alerta inicial de azul a roja y cambio en texto según lo acordado</li>
          </ul>
        </template>
        <br />

        <h2 class="d-inline-block"><span class="badge bg-primary-soft mt-n1">v1.1.2 ¡Feliz año!</span></h2> <span class="text-muted"> – 1 enero, 2022</span>

        <template v-if="showIfContains('bycyoga')">
          <p>
            <strong>BYCYOGA</strong>
          </p>
          <ul class="mb-5">
            <li>Ahora el listado de clases <code>generadas</code> deja de mostrar las clases más viejas. Por ahora muestra hasta 7 días por si necesitan pasar lista, pero la idea es que ese número de días termine en un valor cercano a los 3</li>
            <li>Mejoras de seguridad que produción la creación de clases sin datos</li>
            <li>Ahora los alumnos se muestran ordenados por box</li>
            <li>Mejoras en sistema de reportería</li>
            <li>Pruebas con reporte de alumnos (asistencia)</li>
          </ul>
        </template>
        <br />

        <h2 class="d-inline-block"><span class="badge bg-primary-soft mt-n1">v1.1.1</span></h2> <span class="text-muted"> – 31 diciembre, 2021</span>

        <template v-if="showIfContains('bycyoga')">
          <p>
            <strong>BYCYOGA</strong>
          </p>
          <ul class="mb-5">
            <li>Primera versión de la reportería, generación de reporte de pagos y cargos (en formato Excel / xlsx)</li>
          </ul>
        </template>
        <br />

        <h2 class="d-inline-block"><span class="badge bg-primary-soft mt-n1">v1.1.0</span></h2> <span class="text-muted"> – 30 diciembre, 2021</span>

        <template v-if="showIfContains('bycyoga')">
          <ul>
            <li>Se actualiza pantalla de inicio y saludo</li>
            <li>Ahora aparece arriba la empresa en que estás</li>
          </ul>
          <p>
            <strong>BYCYOGA</strong>
          </p>
          <ul class="mb-5">
            <li>Nuevo campo <code>detalles2</code> en las transacciones manuales para quienes tengan problemas al ingresar detalles</li>
            <li>Mejoras en proceso de generación de clases. Todavía bajo supervisión por casos de clases duplicadas</li>
          </ul>
        </template>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  methods: {
    showIfContains (appName) {
      const { customData } = this.$store.getters.getUserData
      if (customData && customData.currentCompany.length > 0) {
        if (customData.currentCompany.toString() && customData.currentCompany.toString().toLowerCase().indexOf(appName.toLowerCase()) !== -1) {
          // ok es
          return true
        }
      }
      return false
    }
  }
}
</script>

<style>
</style>
