<template>
  <Menu v-if="showMenu" :customData="$store.getters.getUserData.customData" />
  <div class="main-content">
    <router-view/>
  </div>
  <SessionCheck />
  <KeepAlive2 />
</template>

<script>
import Menu from '@/views/Menu.vue'
import SessionCheck from '@/components/SessionCheck.vue'
import KeepAlive2 from '@/components/KeepAlive.vue'

// dashkit theme
import '@/assets/themes/dashkit/css/theme.bundle.css'
import '@/assets/themes/dashkit/css/libs.bundle.css'
// choices
import 'choices.js/public/assets/styles/choices.css'

import '@/assets/vendor/fontawesome-free-5.15.4-web/css/all.css'

export default {
  name: 'App',
  computed: {
    showMenu () {
      const noShow = ['login', 'register', 'password']
      return noShow.indexOf(this.$route.name) === -1
    }
  },
  async mounted () {
    this.$store.dispatch('doUserData', {})
  },
  components: {
    Menu,
    SessionCheck,
    KeepAlive2
  },
  methods: {
    async go () {}
  }
}
</script>

<style lang="less">

/* estilo general */
/* es necesario incluirlo también en algunos elementos como date picker */
@media (prefers-color-scheme: dark) {
  .dark-or-not {
    background-color: #1e3a5c !important;
  }
}

@import '../public/assets/css/theme-dark.bundle.css' screen and (prefers-color-scheme: dark);

/* estilos para formularios */
.must-fix {
  border: 1px solid rgb(230, 55, 87) !important;
}
.must-fix-fixed {
  background: white !important;
  border: 1px solid rgb(0, 217, 126) !important;
}
.invalid-feedback-custom {
  color: #e63757;
  display: block;
  font-size: .8125rem;
  margin-top: 0;
  width: 100%;
}

.link-override {
  color: #12263f;
}
.link-override:hover {}

</style>
