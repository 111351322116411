import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

export default function initProgress (router) {
  router.beforeEach((to, from, next) => {
    NProgress.start()
    return next()
  })

  router.afterEach(() => {
    setTimeout(() => {
      NProgress.done()
    }, 400)
  })
}
