import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'
import messages from '@/plugins/i18n'
import { FontAwesomeIcon } from '@/plugins/font-awesome'
import initProgress from './router/progressbar'
import { locale } from './plugins/locale'

const app = createApp(App)
initProgress(router)

const i18n = createI18n({
  locale,
  fallbackLocale: ['en'],
  messages
})

app.component('fa', FontAwesomeIcon)

app
  .use(i18n)
  .use(store)
  .use(router)
  .mount('#app')
