const messages = {
  en: {
    welcomeMsg: 'Hello!',
    logout: 'logout',
    switch: {
      to: 'Switch to...',
      toCompany: 'Switch to company',
      plain: 'Switch'
    },
    pages: {
      Dia: 'day',
      Clase: 'la clase'
    },
    container: {
      sections: {
        editor: 'editor',
        details: 'details',
        container: 'container'
      },
      alert: {
        delete: 'You are about to delete this'
      },
      general: {
        copyDone: 'In your clipboard!',
        saveError: 'There was an error while saving, please try again',
        done: 'done!',
        saving: 'saving',
        loading: 'loading',
        remove: 'remove',
        add: 'add',
        validate: 'validate',
        new: 'new',
        results: 'results',
        search: 'search',
        prev: 'prev',
        next: 'next',
        save: 'save',
        actions: 'actions'
      },
      multiselect: {
        noResults: 'No results available',
        helpText: 'Start typing to search or just type * to show all results'
      }
    },
    validationText: {
      invalidFields: 'There are invalid fields',
      invalidFields2: 'please fix them before saving again',
      invalidFields3: 'Error list'

    },
    // fields: {
    //   text: '',
    //   // pricePerPerson: 'price per person'
    // },
    login: {
      text: 'Login',
      textSocial: 'using your account',
      textEmail: 'using your email',
      users: 'Users',
      email: 'email',
      password: 'password',
      passwordConfirm: 'password (confirm)',
      login: 'login',
      notAUser: 'not a user?',
      notAUserText: 'If you haven\'t received your invite yet please contat your sales executive',
      unifiedErrorGeneralNoDetails: 'There was a problem while trying to authenticate you, please make sure you are a registered user and try again later',
      loginErrorGeneralNoDetails: 'Your email or password is incorrect. Please try again',
      loginErrorGeneralNetwork: 'There was a network error. Please check your connection and try again',
      forgotPassword: 'forgot your password?',
      errors: {
        soonToBeExpired: 'Your session will expire soon, please login again here',
        expired: 'Your session has expired, please login again',
        generic: 'Could not login, please verify your email and your password and try again',
        noEmail: 'You need to enter an email first'
      }
    },
    register: {
      text: 'login using',
      createUser: 'create user',
      note: 'NOTE: your social account must be linked to your email',
      errors: {
        generic: 'Could not create your user, please verify your password and try again',
        passwords: 'Please make sure passwords are the same and at least {min} characters long'
      }
    },
    passwordReset: {
      text: 'set new password',
      password: 'password',
      passwordConfirm: 'password (confirm)',
      changePassword: 'set new password',
      errors: {
        generic: 'Could not change your password, please verify that the link of this page is the same as the one you received in the email and try again',
        passwords: 'Please make sure passwords are the same and at least {min} characters long'
      },
      success: 'You can now check your email! If you haven\'t heard from us in the next 5 minutes please try again or check your spam folder'
    },
    wrappers: {
      dropzone: {
        preview: 'preview',
        dropHere: 'Drag and drop to upload content!',
        dropHereLine2: '...or click to select'
      },
      preview: {
        preview: 'Preview',
        characters: 'characters'
      }
    },
    apps: {
      demos: {
        pages: {
          demo: 'demo1',
          demo2: 'demo2',
          demo3: 'demo3'
        }
      },
      bycyoga: {
        pages: {
          alumno: 'alumnos',
          transacciones: 'transacciones',
          clasesMolde: 'clases (molde)',
          clasesGeneradas: 'clases generadas',
          horario: 'hoario',
          periodo: 'período',
          transaccionManual: 'transacción manual',
          boxes: 'boxes'
        }
      },
      catalyst: {
        referenceValue: 'Reference value for',
        people: 'people',
        use: 'use this value',
        saveAndCreateQuote: 'Save and create quote',
        saveAndCreatePdf: 'Save and create PDF (it will still be a draft)',
        createDraftBasedOnQuote: 'Create draft based on this quote',
        copyLink: 'copy link',
        activity: 'Activity',
        activityPlus: 'Activity / Extra / Menu',
        chooseText: 'Choose text',
        quote: 'Quote',
        itinerary: 'Itinerary',
        day: 'day',
        schedule: 'schedule'
      }
    },
    components: {
      select: {
        itemSelectText: 'Press to select',
        loadingText: 'Loading...',
        noResultsText: 'No results found',
        noChoicesText: 'No choices to choose from'
      },
      toast: {
        startLoading: 'Loading data...',
        endLoading: 'Done loading!',
        startSaving: 'Saving data...',
        endSaving: 'Done saving!',
        checkErrors: 'Please check all fields for errors!',
        fixedErrors: 'All errors fixed!',
        readyToSave: 'Ready to save!',
        saveError: 'An error ocurred while saving, please fix any possible errors and try again!'
      },
      validate: {
        required: 'this field is required',
        min: 'this field must be greater than {min} (or equal)',
        max: 'this field must be less than {max} (or equal)'
      }
    }

  },
  es: {
    welcomeMsg: '¡¡Hola!!',
    logout: 'cerrar sesión',
    switch: {
      to: 'Cambiar a...',
      toCompany: 'Cambiar a empresa',
      plain: 'Cambiar'
    },
    container: {
      sections: {
        editor: 'editor',
        details: 'detalles',
        container: 'contenedor'
      },
      alert: {
        delete: 'Estás a punto de borrar esto'
      },
      general: {
        copyDone: '¡copiado al portapapeles!',
        saveError: 'Hubo un error al guardar, por favor intentar nuevamente',
        done: '¡listo!',
        saving: 'guardando',
        loading: 'cargando',
        remove: 'borrar',
        add: 'agregar',
        validate: 'validar',
        new: 'nuevo',
        results: 'resultados',
        search: 'buscar',
        prev: 'anterior',
        next: 'siguiente',
        save: 'guardar',
        actions: 'acciones'
      },
      multiselect: {
        noResults: 'No hay datos disponibles',
        helpText: 'Prueba buscar un texto o escribe * para mostrar todos los resultados'
      }
    },
    validationText: {
      invalidFields: 'Hay campos con errores',
      invalidFields2: 'por favor arreglarlos antes de volver a guardar',
      invalidFields3: 'Listado de errores'
    },
    // validation: {
    //   min: 'some min',
    //   min_value: 'some min 2'
    // },
    // fields: {
    //   text: '',
    //   // pricePerPerson: 'precio por persona (global en i18n)'
    // },
    login: {
      text: 'Entrar',
      textSocial: 'usando tu cuenta',
      textEmail: 'usando tu email',
      users: 'usuarios',
      email: 'email',
      password: 'clave',
      passwordConfirm: 'clave (repetir para confirmar)',
      login: 'entrar',
      notAUser: '¿no eres usuario todavía?',
      notAUserText: 'Si no has recibido tu invitación por favor contacta a tu ejecutivo de ventas',
      unifiedErrorGeneralNoDetails: 'Hubo un problema al intententar autenticarte, por favor verifica que seas un usuario registrado y vuelve a intenentar nuevamente más tarde',
      loginErrorGeneralNoDetails: 'Tu email y/o clave son incorrectos. Por favor vuelve a intentar',
      loginErrorGeneralNetwork: 'Hubo un problema de red. Por favor revisa tu conexión y vuelve a intentar',
      forgotPassword: '¿olvidaste tu clave?',
      errors: {
        soonToBeExpired: 'Tu sesión pronto expirará, por favor iniciar sesión nuevamente aquí',
        expired: 'Tu sesión ha expirado, por favor vuelve a acceder',
        generic: 'No fue posible acceder, por favor revisa tu email y clave e intenta nuevamente',
        noEmail: 'Es necesario que ingreses un email primero'
      }
    },
    register: {
      text: 'entrar usando',
      createUser: 'crear usuario',
      note: 'NOTA: tu cuenta de red social debe estar asociada al email',
      errors: {
        generic: 'No se pudo crear al usuario, por favor verificar usuario y clave e intentar nuevamente',
        passwords: 'Por favor verificar que las dos claves sean iguales y que tengan al menos {min} caracteres'
      }
    },
    passwordReset: {
      text: 'crear una nueva clave',
      password: 'clave',
      passwordConfirm: 'clave (confirmar)',
      changePassword: 'crear clave',
      errors: {
        generic: 'No fue posible cambiar la clave, por favor verifica que el link de esta página corresponda con el link que recibiste en el correo electrónico y vuelve a intentar',
        passwords: 'por favor asegúrate que las dos claves sean iguales y que tengan al menos {min} caracteres'
      }
    },
    wrappers: {
      dropzone: {
        preview: 'ver',
        dropHere: 'Arrastrar archivos acá para subirlos',
        dropHereLine2: '...o hacer click y seleccionar'
      },
      preview: {
        preview: 'Vista previa',
        characters: 'caracteres'
      }
    },
    apps: {
      catalyst: {
        referenceValue: 'Valor referencial para',
        people: 'personas',
        use: 'usar este valor',
        saveAndCreateQuote: 'Guardar y crear cotización',
        saveAndCreatePdf: 'Guardar y crear PDF (mantener como borrador)',
        createDraftBasedOnQuote: 'Crear borrador en base a esta cotización',
        copyLink: 'copiar link',
        activity: 'Actividad',
        activityPlus: 'Actividad / Extras / Menú',
        chooseText: 'Elegir texto',
        quote: 'Cotización',
        itinerary: 'Itinerario',
        day: 'día',
        schedule: 'horario'
      }
    },
    components: {
      select: {
        itemSelectText: 'Presiona para seleccionar',
        loadingText: 'Cargando...',
        noResultsText: 'No se encontraron resultado',
        noChoicesText: 'No hay opciones disponibles'
      },
      toast: {
        startLoading: 'Cargando datos...',
        endLoading: '¡Datos cargados!',
        startSaving: 'Guardando datos...',
        endSaving: '¡Datos guardados!',
        checkErrors: 'Por favor revisa los errores en los campos',
        fixedErrors: 'Errores arreglados',
        readyToSave: 'Listo para guardar',
        saveError: 'Un error ocurrió al guardar, por favor arregla cualquier posible error e intenta nuevamente'
      },
      validate: {
        required: 'este campo es obligatorio',
        min: 'este campo debe ser mayor que {min} (o igual)',
        max: 'este campo debe ser mayor que {max} (o igual)'
      }
    }
  }
}

export default messages
