<template>
  <nav v-if="this.$store?.getters?.getMenuStatus" class="navbar navbar-vertical fixed-start navbar-expand-md navbar-light" id="sidebar">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        @click.prevent="toggler('sidebarCollapse')"
        aria-controls="sidebarCollapse"
        aria-expanded="false">
        <span class="navbar-toggler-icon"></span>
      </button>

      <router-link to="/" active-class="active" class="nav-linkQQQ navbar-brand">
        <img src="@/assets/img/logo_wawentu_v2.svg" class="navbar-brand-img mx-auto" alt="...">
      </router-link>

      <div class="navbar-user d-md-none">
        <div class="dropdown">
          <a href="#" id="sidebarIcon" class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="avatar avatar-sm avatar-onlineQQQ">
              <!-- <img src="@/assets/img/avatars/profiles/avatar-1.jpg" class="avatar-img rounded-circle" alt="..."> -->
            </div>
          </a>

          <!-- Menu -->
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarIcon">
            <router-link to="/apps/common/switcher" active-class="active" class="nav-link">{{$tm('switch.to')}}</router-link>
            <hr class="dropdown-divider">
            <a href="./sign-in.html" class="dropdown-item">Logout</a>
          </div>

        </div>

      </div>

      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="sidebarCollapse" ref="sidebarCollapse">

        <!-- Form -->
        <form class="mt-4 mb-3 d-md-none visually-hidden">
          <div class="input-group input-group-rounded input-group-merge input-group-reverse">
            <input class="form-control" type="search" placeholder="Search" aria-label="Search">
            <div class="input-group-text">
              <span class="fe fe-search"></span>
            </div>
          </div>
        </form>

        <!-- Navigation -->
        <ul class="navbar-nav visually-hidden">
          <li class="nav-item d-md-none">
            <a class="nav-link" href="#sidebarModalActivity" data-bs-toggle="modal">
              <span class="fe fe-bell"></span> Notifications
            </a>
          </li>
          <li class="nav-item">
            <router-link to="/login" active-class="active" class="nav-link"><i class="fe fe-layout"></i> Dashboard</router-link>
          </li>
        </ul>

        <template v-if="customData && customData.accessData && customData.accessData.apps">
          <h6 class="navbar-heading">
            Apps
          </h6>

          <ul v-if="customData.accessData.apps.appDemo" class="navbar-nav visually-hidden">
            <li class="nav-item">
              <a class="nav-link" href="#appsDemo" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="appsDemo">
                <i class="fe fe-git-branch"></i> Demos (dep)
              </a>
              <div class="collapse showOFF" id="appsDemo">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <router-link to="/apps/demos/demo" active-class="active" class="nav-link"><span @click="collapse">Demo</span> <span class="badge bg-primary ms-auto">new!</span></router-link>
                  </li>
                  <li class="nav-item">
                    <li class="nav-item">
                      <router-link to="/apps/demos/demo/edit/60f848daa86bc4d526080337" active-class="active" class="nav-link"><span @click="collapse">Ejemplo 1</span></router-link>
                    </li>
                  </li>
                  <li class="nav-item">
                    <li class="nav-item">
                      <router-link to="/apps/demos/demo2" active-class="active" class="nav-link"><span @click="collapse">Demo2</span></router-link>
                    </li>
                  </li>
                  <li class="nav-item">
                    <li class="nav-item">
                      <router-link to="/apps/demos/demo3" active-class="active" class="nav-link"><span @click="collapse">Demo3</span></router-link>
                    </li>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul v-if="customData.accessData.apps.appDemo" class="navbar-nav">
            <li class="nav-item">
              <a
                ref="appsDemoController"
                @click.prevent="toggler('appsDemo', 'show')"
                class="nav-link"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="true">
                <i class="fe fe-git-branch"></i> Demos
              </a>
              <div class="collapse show" ref="appsDemo">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <router-link to="/apps/demos/demo" active-class="active" class="nav-link"><span @click="collapse">Demo</span> <span class="badge bg-primary ms-auto">new!</span></router-link>
                  </li>
                  <li class="nav-item">
                    <li class="nav-item">
                      <router-link to="/apps/demos/demo/edit/60f848daa86bc4d526080337" active-class="active" class="nav-link"><span @click="collapse">Ejemplo 1</span></router-link>
                    </li>
                  </li>
                  <li class="nav-item">
                    <li class="nav-item">
                      <router-link to="/apps/demos/demo2" active-class="active" class="nav-link"><span @click="collapse">Demo2</span></router-link>
                    </li>
                  </li>
                  <li class="nav-item">
                    <li class="nav-item">
                      <router-link to="/apps/demos/demo3" active-class="active" class="nav-link"><span @click="collapse">Demo3</span></router-link>
                    </li>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul v-if="customData.accessData.apps.appHyd" class="navbar-nav">
            <li class="nav-item">
              <a
                ref="appsHydController"
                @click.prevent="toggler('appsHyd', 'show')"
                class="nav-link"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="true">
                <i class="fe fe-git-branch"></i> Hyd
              </a>
              <div class="collapse show" ref="appsHyd">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <router-link to="/apps/hyd/rut" active-class="active" class="nav-link"><span @click="collapse">ruts</span></router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul v-if="customData.accessData.apps.appLinks" class="navbar-nav">
            <li class="nav-item">
              <a
                ref="appsLinksController"
                @click.prevent="toggler('appsLinks', 'show')"
                class="nav-link"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="true">
                <i class="fe fe-git-branch"></i> Links
              </a>
              <div class="collapse show" ref="appsLinks">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <router-link to="/apps/links/link" active-class="active" class="nav-link"><span @click="collapse">links</span></router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul v-if="customData.accessData.apps.appBycyoga" class="navbar-nav">
            <li class="nav-item">
              <a
                ref="appsBycyogaController"
                @click.prevent="toggler('appsBycyoga', 'show')"
                class="nav-link"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="true">
                <i class="fe fe-git-branch"></i> Bycyoga <!-- <span class="badge bg-primary ms-auto">new!</span> -->
              </a>
              <div class="collapse show" ref="appsBycyoga">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/clase-profesor" active-class="active" class="nav-link" ><span @click="collapse">clases</span> </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/alumno-account" active-class="active" class="nav-link"><span @click="collapse">{{$tm('apps.bycyoga.pages.alumno')}} </span></router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/plan" active-class="active" class="nav-link"><span @click="collapse">planes </span><span class="badge bg-warning ms-auto">experimental!</span></router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/transaccion" active-class="active" class="nav-link"><span @click="collapse">transacciones</span></router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul v-if="customData.accessData.apps.appBycyogaMaster" class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" ref="appsBycyogaManagerController" @click.prevent="toggler('appsBycyogaManager', 'show')" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="appsBycyogaManager">
                <i class="fe fe-git-branch"></i> Bycyoga MANAGER
              </a>
              <div class="collapse show" ref="appsBycyogaManager">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/report-full" active-class="active" class="nav-link"><span @click="collapse">reportes consolidados</span><span class="badge bg-warning ms-auto">experimental</span></router-link>
                  </li>

                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/alumno" active-class="active" class="nav-link"><span @click="collapse">alumno (general)</span></router-link>
                  </li>

                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/dia" active-class="active" class="nav-link"><span @click="collapse">día</span> <span class="badge bg-danger ms-auto">mantenedor</span></router-link>
                  </li>

                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/tarifa" active-class="active" class="nav-link"><span @click="collapse">tarifa</span> <span class="badge bg-danger ms-auto">mantenedor</span></router-link>
                  </li>

                </ul>
              </div>
            </li>
          </ul>

        </template>

        <!-- Divider -->
        <hr class="navbar-divider my-3">

        <!-- Heading -->
        <h6 class="navbar-heading">
          Account
        </h6>

        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link id="switch-to" to="/apps/common/switcher" active-class="active" class="nav-link"><i class="fe fe-repeat"></i> <span @click="collapse">{{$tm('switch.to')}}</span></router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" ref="sidebarAuthController" @click.prevent="toggler('sidebarAuth', 'show')" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarAuth">
              <i class="fe fe-user"></i> Me
            </a>
            <div class="collapse showQQQ" ref="sidebarAuth">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <router-link to="/login" active-class="active" class="nav-link">Login</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/logout" active-class="active" class="nav-link">Logout</router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>

        <!-- admin -->
        <template v-if="customData && customData.accessData && customData.accessData.apps && customData.accessData.admin">
          <!-- Divider -->
          <hr class="navbar-divider my-3">
          <!-- Heading -->
          <h6 class="navbar-heading">
            Admin
          </h6>

          <ul v-if="customData.accessData.admin && customData.accessData.apps.appBycyoga" class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" @click.prevent="toggler('appsBycyogaAdmin', 'show')" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="appsBycyogaAdmin">
                <i class="fe fe-star"></i> Bycyoga <!-- <span class="badge bg-primary ms-auto">new!</span> -->
              </a>
              <div class="collapse show" ref="appsBycyogaAdmin">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/clase" active-class="active" class="nav-link"><span @click="collapse">clases</span> <span class="badge bg-warning ms-auto">molde</span>
                      <!-- <span class="badge bg-primary ms-auto">new!</span> -->
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/generar-clases" active-class="active" class="nav-link"><span @click="collapse">generar clases</span> <!-- <span class="badge bg-warning ms-auto">molde</span> -->
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/clase-instance" active-class="active" class="nav-link"><span @click="collapse">clases generadas</span> </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/horario" active-class="active" class="nav-link"><span @click="collapse">horario</span></router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/periodo" active-class="active" class="nav-link"><span @click="collapse">periodo</span></router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/plan-admin" active-class="active" class="nav-link"><span @click="collapse">planes </span><span class="badge bg-warning ms-auto">experimental!</span></router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/transaccion-stash" active-class="active" class="nav-link"><span @click="collapse">transacción manual</span> <span class="badge bg-primary ms-auto">manual</span></router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/box" active-class="active" class="nav-link"><span @click="collapse">boxes</span> </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/apps/bycyoga/report" active-class="active" class="nav-link"><span @click="collapse">reportes</span> <span class="badge bg-warning ms-auto">experimental</span></router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <ul v-if="customData.accessData.root" class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" @click.prevent="toggler('sidebarAdminRoot', 'show')" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarAdminRoot">
                <i class="fe fe-award"></i> Super Admin
              </a>
              <div class="collapse show" ref="sidebarAdminRoot">
                <ul class="nav nav-sm flex-column">

                  <li class="nav-item">
                    <router-link to="/apps/root/feriado" active-class="active" class="nav-link"><span @click="collapse">feriado</span></router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/apps/root/company" active-class="active" class="nav-link"><span @click="collapse">company</span></router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/apps/root/usuario" active-class="active" class="nav-link"><span @click="collapse">usuario</span>
                      <!-- <span class="badge bg-primary ms-auto">new!</span> -->
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/apps/root/usuario-company" active-class="active" class="nav-link"><span @click="collapse">usuario-company</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </template>

        <!-- parde de abajo -->
        <div class="mt-auto"></div>
          <!-- para desactivar / activar hacerlo acá -->
          <div class="visually-hidden navbar-user d-none d-md-flex" id="sidebarUser">
            <a @click.prevent="toggler('sidebarIconCopy', 'show')" class="navbar-user-link" data-bs-toggle="modal">
              <span class="icon">
                <i class="fe fe-bell"></i>
              </span>
            </a>
            <div class="dropup">
              <a href="#" ref="sidebarIconCopy" class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div class="avatar avatar-sm avatar-onlineQQ">
                  <img src="@/assets/img/logo_wawentu_v2.svg" class="avatar-img rounded-circle" alt="...">
                </div>
              </a>

              <!-- Menu -->
              <div class="dropdown-menu" aria-labelledby="sidebarIconCopy">
                <a href="./profile-posts.html" class="dropdown-item">Profile</a>
                <a href="./account-general.html" class="dropdown-item">Settings</a>
                <hr class="dropdown-divider">
                <a href="./sign-in.html" class="dropdown-item">Logout</a>
              </div>

            </div>
          </div>
      </div>
    </div>
  </nav>
  <nav v-else>
    <button class="btn btn-primary btn-sm" @click="this.$store.commit('setMenuStatus', true)">&lt;&lt;</button>
  </nav>

  <!-- Modal: Activity -->
  <div class="modal fade" id="sidebarModalActivity" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-vertical" role="document">
      <div class="modal-content">
        <div class="modal-header">

          <!-- Title -->
          <h4 class="modal-title">
            Notifications
          </h4>

        </div>
        <div class="modal-body">
          <div class="tab-content">
            <div class="tab-pane fade show active" id="modalActivityAction">
              <!-- List group -->
              <div class="list-group list-group-flush list-group-activity my-n3">
                <a class="list-group-item text-reset" href="#!">
                  <div class="row">
                    <div class="col-auto">

                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <div class="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                          <i class="fe fe-mail"></i>
                        </div>
                      </div>

                    </div>
                    <div class="col ml-n2">

                      <!-- Heading -->
                      <h5 class="mb-1">
                        Launchday 1.4.0 update email sent
                      </h5>

                      <!-- Text -->
                      <p class="small text-gray-700 mb-0">
                        Sent to all 1,851 subscribers over a 24 hour period
                      </p>

                      <!-- Time -->
                      <small class="text-muted">
                        2m ago
                      </small>

                    </div>
                  </div> <!-- / .row -->
                </a>
                <a class="list-group-item text-reset" href="#!">
                  <div class="row">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <div class="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                          <i class="fe fe-archive"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col ml-n2">

                      <!-- Heading -->
                      <h5 class="mb-1">
                        New project "Goodkit" created
                      </h5>

                      <!-- Text -->
                      <p class="small text-gray-700 mb-0">
                        Looks like there might be a new theme soon.
                      </p>

                      <!-- Time -->
                      <small class="text-muted">
                        2h ago
                      </small>

                    </div>
                  </div> <!-- / .row -->
                </a>
                <a class="list-group-item text-reset" href="#!">
                  <div class="row">
                    <div class="col-auto">

                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <div class="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                          <i class="fe fe-code"></i>
                        </div>
                      </div>

                    </div>
                    <div class="col ml-n2">

                      <!-- Heading -->
                      <h5 class="mb-1">
                        Dashkit 1.5.0 was deployed.
                      </h5>

                      <!-- Text -->
                      <p class="small text-gray-700 mb-0">
                        A successful to deploy to production was executed.
                      </p>

                      <!-- Time -->
                      <small class="text-muted">
                        2m ago
                      </small>

                    </div>
                  </div> <!-- / .row -->
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Menu',
  props: ['data', 'customData'],
  data () {
    return {
      // showMenu: this.$store.getters.getUserData || true
      data2: this.$store.getters.getUserData // no funciona en todos los casos
    }
  },
  mounted () {},
  methods: {
    toggler (refName, className = 'collapse') {
      this.$refs[refName].classList.toggle(className)
      try {
        const element = this.$refs[refName + 'Controller']
        // element.toggle('collapse')
        if (element.getAttribute('aria-expanded')) {
          element.setAttribute('aria-expanded', false)
        } else {
          element.setAttribute('aria-expanded', true)
        }
      } catch (e) {}
    },
    toggleMenuDelay () {
      console.log('>>> here toggle (con delay)')
      setTimeout(function () {
        this.$refs.sidebarCollapse?.classList?.toggle('collapse')
      }, 600)
    },
    toggleMenu () {
      console.log('>>> here toggle', this.$refs.sidebarCollapse?.classList?.toggle('collapse'))
    },
    collapse () {
      const self = this
      setTimeout(function () {
        self.$refs.sidebarCollapse?.classList?.add('collapse')
      }, 600)
    }
  }
}
</script>

<style>
</style>
