<template></template>

<script>
import axios from 'axios'

export default {
  name: 'keep-alive',
  data () {
    return {
      showJWTWarning: false,
      showJWTDanger: false,
      debugMessage: '',
      intervalId: ''
    }
  },
  mounted: async function () {
    this.executeCheck()
    console.log('>>> keep alive started!')
    this.startTimer()
  },
  methods: {
    executeCheck () {
      // console.log('hola')
      axios.get(process.env.VUE_APP_API_SERVER + '/warmup')
    },
    startTimer () {
      const self = this
      self.executeCheck()
      // self.intervalId = setInterval(function () {
      //   self.executeCheck()
      // }, 1000 * 250 * 1)
    }
  }
}
</script>
