// @/plugins/locale.js

// const langs = { es, en }
let locale = navigator ? navigator.language : 'en'
if (locale.indexOf('-') !== -1) locale = locale.split('-')[0]

// only valid locales
if (['en', 'es'].indexOf(locale) === -1) locale = 'en'
// if (!langs[locale]) locale = 'en' // fallback locale

// TEMPORAL OVERRIDE
// locale = 'en'
// locale = 'es'
console.log('>>> Using locale:', locale)

export { locale }
