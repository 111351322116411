<template>
  <div class="main-panel">
    <div
      v-if="showJWTWarning"
      class="sw-update-popup sw-update-popup-warning btn btn-warning"
    ><a href="/login">{{ $tm('login.errors.soonToBeExpired') }}{{debugMessage}}</a></div>
    <div
      v-if="showJWTDanger"
      class="sw-update-popup sw-update-popup-danger btn btn-danger"
    ><a href="/login">{{ $tm('login.errors.expired') }}{{debugMessage}}</a></div>
  </div>
</template>

<script>

export default {
  name: 'session-check',
  data () {
    return {
      showJWTWarning: false,
      showJWTDanger: false,
      debugMessage: '',
      intervalId: ''
    }
  },
  mounted: async function () {
    this.startTimer()
    // this.$store.commit('verifyLogin')
    // console.log('>>>> entering verifyLogin')
    try {
      if (window) {
        const jwt = window.localStorage.getItem('jwt')
        const jwtExp = window.localStorage.getItem('jwt-exp')
        this.$store.commit('setJwt', jwt)
        this.$store.commit('setJwtExp', jwtExp)
      }
    } catch (e) {}
  },
  methods: {
    executeCheck () {
      let tsTemp = this.$store.getters.getJwtExp
      if (!tsTemp) tsTemp = window ? window.localStorage.getItem('jwtExp') : 0
      tsTemp = parseInt(tsTemp)
      const delta = tsTemp - Date.now()
      const minutes = delta / (1000 * 60)
      const hours = minutes / 60

      this.showJWTWarning = false
      this.showJWTDanger = false
      if (minutes <= 30 && minutes > 0) {
        this.showJWTWarning = true
        console.log('>>> checking session (token expiration). Hours left', hours)
      } else if (minutes <= 0) {
        this.showJWTDanger = true
        console.log('>>> checking session (token EXPIRED). Hours left', hours)
      }
    },
    startTimer () {
      const self = this
      self.executeCheck()
      self.intervalId = setInterval(function () {
        self.executeCheck()
      }, 1000 * 10 * 1)
    }
  }
}
</script>
<style scoped>
.sw-update-popup {
  position: fixed;
  right: 1em;
  bottom: 1em;
  padding: 1em;
  /*border: 1px solid #3eaf7c;*/
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 2;
  padding-left: 35px;
  padding-right: 35px;
}

.sw-update-popup-warning a {
  /*color:  white;*/
  color:  #283e59;
}
.sw-update-popup-danger a {
  /*color:  white;*/
  color:  white;
}
</style>
