import router from '../router'

export const authGuard = (to, from, next) => {
  try {
    if (window) {
      const jwt = window.localStorage.getItem('jwt')
      const jwtExp = window.localStorage.getItem('jwtExp')
      if (!jwt || !jwtExp || jwt === '' || jwtExp === '') {
        window.localStorage.setItem('jwtFrom', to.fullPath)
        router.push({ path: '/login' })
      } else {
        if ((jwtExp - Date.now()) > (1000 * 60 * 5)) {
          return next()
        } else {
          window.localStorage.setItem('jwtFrom', to.fullPath)
          router.push({ path: '/login' })
        }
      }
    }
  } catch (e) {
    window.localStorage.setItem('jwtFrom', to.fullPath)
    router.push({ path: '/login' })
  }
}
