import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { authGuard } from './authGuard.js'
import store from '../store/'

const DashboardLayout = () => import(/* webpackChunkName: "dashboardLayout" */ '@/views/Dashboard.vue')

// temp
const Login = () => import(/* webpackChunkName: "CommonLogin" */ '@/components/Login.vue')
const Logout = () => import(/* webpackChunkName: "CommonLogout" */ '@/components/Logout.vue')
const LoginOauth = () => import(/* webpackChunkName: "CommonLoginAuth" */ '@/components/LoginOauth.vue')
const AppsSwitcherContainer = () => import(/* webpackChunkName: "AppsCommonSwitcherContainer" */ '@/components/apps/common/switcher/Container.vue')
const AppsSwitcherOldContainer = () => import(/* webpackChunkName: "AppsCommonSwitcherContainer" */ '@/components/apps/common/switcher_old/Container.vue')

// links
const AppsDemosDemoContainer = () => import(/* webpackChunkName: "AppsAppsDemosDemoContainer" */ '@/components/apps/demos/demo/Container.vue')
const AppsDemosDemoEditor = () => import(/* webpackChunkName: "AppsDemosDemoEditor" */ '@/components/apps/demos/demo/Editor.vue')
const AppsDemosDemo2Container = () => import(/* webpackChunkName: "AppsAppsDemosDemo2Container" */ '@/components/apps/demos/demo2/Container.vue')
const AppsDemosDemo3Container = () => import(/* webpackChunkName: "AppsAppsDemosDemo3Container" */ '@/components/apps/demos/demo3/Container.vue')

// bycyoga
const AppsBycyogaClaseContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaClaseContainer" */ '@/components/apps/bycyoga/clase/Container.vue')
const AppsBycyogaClaseEditor = () => import(/* webpackChunkName: "AppsBycyogaClaseEditor" */ '@/components/apps/bycyoga/clase/Editor.vue')
const AppsBycyogaClaseInstanceContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaClaseInstanceContainer" */ '@/components/apps/bycyoga/clase-instance/Container.vue')
const AppsBycyogaClaseInstanceEditor = () => import(/* webpackChunkName: "AppsBycyogaClaseInstanceEditor" */ '@/components/apps/bycyoga/clase-instance/Editor.vue')
const AppsBycyogaClaseProfesorContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaClaseProfesorContainer" */ '@/components/apps/bycyoga/clase-profesor/Container.vue')
const AppsBycyogaClaseProfesorEditor = () => import(/* webpackChunkName: "AppsBycyogaClaseProfesorEditor" */ '@/components/apps/bycyoga/clase-profesor/Editor.vue')
const AppsBycyogaClaseBoxContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaClaseBoxContainer" */ '@/components/apps/bycyoga/clase-box/Container.vue')
const AppsBycyogaClaseBoxEditor = () => import(/* webpackChunkName: "AppsBycyogaClaseBoxEditor" */ '@/components/apps/bycyoga/clase-box/Editor.vue')
const AppsBycyogaAlumnoContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaAlumnoContainer" */ '@/components/apps/bycyoga/alumno/Container.vue')
const AppsBycyogaAlumnoEditor = () => import(/* webpackChunkName: "AppsBycyogaAlumnoEditor" */ '@/components/apps/bycyoga/alumno/Editor.vue')
const AppsBycyogaAlumnoAccountContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaAlumnoAccountContainer" */ '@/components/apps/bycyoga/alumno-account/Container.vue')
const AppsBycyogaAlumnoAccountEditor = () => import(/* webpackChunkName: "AppsBycyogaAlumnoAccountEditor" */ '@/components/apps/bycyoga/alumno-account/Editor.vue')
const AppsBycyogaAlumnoPlanContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaAlumnoPlanContainer" */ '@/components/apps/bycyoga/alumno-plan/Container.vue')
const AppsBycyogaAlumnoPlanEditor = () => import(/* webpackChunkName: "AppsBycyogaAlumnoPlanEditor" */ '@/components/apps/bycyoga/alumno-plan/Editor.vue')
const AppsBycyogaHorarioContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaHorarioContainer" */ '@/components/apps/bycyoga/horario/Container.vue')
const AppsBycyogaHorarioEditor = () => import(/* webpackChunkName: "AppsBycyogaHorarioEditor" */ '@/components/apps/bycyoga/horario/Editor.vue')
const AppsBycyogaDiaContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaDiaContainer" */ '@/components/apps/bycyoga/dia/Container.vue')
const AppsBycyogaDiaEditor = () => import(/* webpackChunkName: "AppsBycyogaDiaEditor" */ '@/components/apps/bycyoga/dia/Editor.vue')
const AppsBycyogaPeriodoContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaPeriodoContainer" */ '@/components/apps/bycyoga/periodo/Container.vue')
const AppsBycyogaPeriodoEditor = () => import(/* webpackChunkName: "AppsBycyogaPeriodoEditor" */ '@/components/apps/bycyoga/periodo/Editor.vue')
const AppsBycyogaTarifaContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaTarifaContainer" */ '@/components/apps/bycyoga/tarifa/Container.vue')
const AppsBycyogaTarifaEditor = () => import(/* webpackChunkName: "AppsBycyogaTarifaEditor" */ '@/components/apps/bycyoga/tarifa/Editor.vue')
const AppsBycyogaTransaccionContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaTransaccionContainer" */ '@/components/apps/bycyoga/transaccion/Container.vue')
const AppsBycyogaTransaccionEditor = () => import(/* webpackChunkName: "AppsBycyogaTransaccionEditor" */ '@/components/apps/bycyoga/transaccion/Editor.vue')
const AppsBycyogaTransaccionStashContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaTransaccionStashContainer" */ '@/components/apps/bycyoga/transaccion-stash/Container.vue')
const AppsBycyogaTransaccionStashEditor = () => import(/* webpackChunkName: "AppsBycyogaTransaccionStashEditor" */ '@/components/apps/bycyoga/transaccion-stash/Editor.vue')
const AppsBycyogaTransaccionTipoContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaTransaccionTipoContainer" */ '@/components/apps/bycyoga/transaccion-tipo/Container.vue')
const AppsBycyogaTransaccionTipoEditor = () => import(/* webpackChunkName: "AppsBycyogaTransaccionTipoEditor" */ '@/components/apps/bycyoga/transaccion-tipo/Editor.vue')
const AppsBycyogaBoxContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaBoxContainer" */ '@/components/apps/bycyoga/box/Container.vue')
const AppsBycyogaBoxEditor = () => import(/* webpackChunkName: "AppsBycyogaBoxEditor" */ '@/components/apps/bycyoga/box/Editor.vue')

const AppsBycyogaPlanContainer = () => import(/* webpackChunkName: "AppsAppsBycyogaPlanContainer" */ '@/components/apps/bycyoga/plan/Container.vue')
const AppsBycyogaPlanEditor = () => import(/* webpackChunkName: "AppsBycyogaPlanEditor" */ '@/components/apps/bycyoga/plan/Editor.vue')
const AppsBycyogaPlanAdminContainer = () => import(/* webpackChunkName: "AppsBycyogaPlanAdminContainer" */ '@/components/apps/bycyoga/plan-admin/Container.vue')
const AppsBycyogaPlanAdminEditor = () => import(/* webpackChunkName: "AppsBycyogaPlanAdminEditor" */ '@/components/apps/bycyoga/plan-admin/Editor.vue')

const AppsBycyogaReport = () => import(/* webpackChunkName: "AppsBycyogaReport" */ '@/components/apps/bycyoga/report/Report.vue')
const AppsBycyogaReportFull = () => import(/* webpackChunkName: "AppsBycyogaReportFull" */ '@/components/apps/bycyoga/report/ReportFull.vue')
const AppsBycyogaGenerarClases = () => import(/* webpackChunkName: "AppsBycyogaGenerarClases" */ '@/components/apps/bycyoga/GenerarClases.vue')

// motion
const AppsMotionOps = () => import(/* webpackChunkName: "AppsMotionOps" */ '@/components/apps/motion/Ops.vue')
const AppsMotionReports = () => import(/* webpackChunkName: "AppsMotionReports" */ '@/components/apps/motion/Reports.vue')
const AppsMotionLive = () => import(/* webpackChunkName: "AppsMotionLive" */ '@/components/apps/motion/Live.vue')
const AppsMotionAlumnoContainer = () => import(/* webpackChunkName: "AppsAppsMotionAlumnoContainer" */ '@/components/apps/motion/alumno/Container.vue')
const AppsMotionAlumnoEditor = () => import(/* webpackChunkName: "AppsMotionAlumnoEditor" */ '@/components/apps/motion/alumno/Editor.vue')
const AppsMotionProfesorContainer = () => import(/* webpackChunkName: "AppsAppsMotionProfesorContainer" */ '@/components/apps/motion/profesor/Container.vue')
const AppsMotionProfesorEditor = () => import(/* webpackChunkName: "AppsMotionProfesorEditor" */ '@/components/apps/motion/profesor/Editor.vue')

const AppsMotionPlanContainer = () => import(/* webpackChunkName: "AppsAppsMotionPlanContainer" */ '@/components/apps/motion/plan/Container.vue')
const AppsMotionPlanEditor = () => import(/* webpackChunkName: "AppsMotionPlanEditor" */ '@/components/apps/motion/plan/Editor.vue')

const AppsMotionBloqueGeneralContainer = () => import(/* webpackChunkName: "AppsAppsMotionBloqueGeneralContainer" */ '@/components/apps/motion/bloque-general/Container.vue')
const AppsMotionBloqueGeneralEditor = () => import(/* webpackChunkName: "AppsMotionBloqueGeneralEditor" */ '@/components/apps/motion/bloque-general/Editor.vue')

const AppsMotionBloqueAlumnoProfesorContainer = () => import(/* webpackChunkName: "AppsAppsMotionBloqueAlumnoProfesorContainer" */ '@/components/apps/motion/bloque-alumno-profesor/Container.vue')
const AppsMotionBloqueAlumnoProfesorEditor = () => import(/* webpackChunkName: "AppsMotionBloqueAlumnoProfesorEditor" */ '@/components/apps/motion/bloque-alumno-profesor/Editor.vue')

const AppsMotionViaContainer = () => import(/* webpackChunkName: "AppsAppsMotionViaContainer" */ '@/components/apps/motion/via/Container.vue')
const AppsMotionViaEditor = () => import(/* webpackChunkName: "AppsMotionViaEditor" */ '@/components/apps/motion/via/Editor.vue')

// hyd
const AppsHydRutContainer = () => import(/* webpackChunkName: "AppsAppsHydRutContainer" */ '@/components/apps/hyd/rut/Container.vue')
const AppsHydRutEditor = () => import(/* webpackChunkName: "AppsHydRutEditor" */ '@/components/apps/hyd/rut/Editor.vue')

// links
const AppsLinksLinkContainer = () => import(/* webpackChunkName: "AppsLinksLinkContainer" */ '@/components/apps/links/link/Container.vue')
const AppsLinksLinkEditor = () => import(/* webpackChunkName: "AppsLinksLinkEditor" */ '@/components/apps/links/link/Editor.vue')

// root
const AppsRootCompanyContainer = () => import(/* webpackChunkName: "AppsRootCompanyContainer" */ '@/components/apps/root/company/Container.vue')
const AppsRootCompanyEditor = () => import(/* webpackChunkName: "AppsRootCompanyEditor" */ '@/components/apps/root/company/Editor.vue')
const AppsRootUsuarioStashContainer = () => import(/* webpackChunkName: "AppsRootUsuarioStashContainer" */ '@/components/apps/root/usuario-stash/Container.vue')
const AppsRootUsuarioStashEditor = () => import(/* webpackChunkName: "AppsRootUsuarioStashEditor" */ '@/components/apps/root/usuario-stash/Editor.vue')
const AppsRootUsuarioCompanyContainer = () => import(/* webpackChunkName: "AppsRootUsuarioCompanyContainer" */ '@/components/apps/root/usuario-company/Container.vue')
const AppsRootUsuarioCompanyEditor = () => import(/* webpackChunkName: "AppsRootUsuarioCompanyEditor" */ '@/components/apps/root/usuario-company/Editor.vue')
const AppsRootUsuarioContainer = () => import(/* webpackChunkName: "AppsRootUsuarioContainer" */ '@/components/apps/root/usuario/Container.vue')
const AppsRootUsuarioEditor = () => import(/* webpackChunkName: "AppsRootUsuarioEditor" */ '@/components/apps/root/usuario/Editor.vue')
const AppsRootFeriadoContainer = () => import(/* webpackChunkName: "AppsRootFeriadoContainer" */ '@/components/apps/root/feriado/Container.vue')
const AppsRootFeriadoEditor = () => import(/* webpackChunkName: "AppsRootFeriadoEditor" */ '@/components/apps/root/feriado/Editor.vue')

const AppsRootModules = [
  {
    path: 'root/company',
    name: 'AppsRootCompanyContainer',
    component: AppsRootCompanyContainer,
    meta: { app: 'root', code: 'company' }
  }, {
    path: 'root/company/:mode/:id',
    name: 'AppsRootCompanyEditor',
    component: AppsRootCompanyEditor,
    meta: { app: 'root', code: 'company' }
  }, {
    path: 'root/usuario-stash',
    name: 'AppsRootUsuarioStashContainer',
    component: AppsRootUsuarioStashContainer,
    meta: { app: 'root', code: 'usuario-stash' }
  }, {
    path: 'root/usuario-stash/:mode/:id',
    name: 'AppsRootUsuarioStashEditor',
    component: AppsRootUsuarioStashEditor,
    meta: { app: 'root', code: 'usuario-stash' }
  }, {
    path: 'root/usuario-company',
    name: 'AppsRootUsuarioCompanyContainer',
    component: AppsRootUsuarioCompanyContainer,
    meta: { app: 'root', code: 'usuario-company' }
  }, {
    path: 'root/usuario-company/:mode/:id',
    name: 'AppsRootUsuarioCompanyEditor',
    component: AppsRootUsuarioCompanyEditor,
    meta: { app: 'root', code: 'usuario-company' }
  }, {
    path: 'root/usuario',
    name: 'AppsRootUsuarioContainer',
    component: AppsRootUsuarioContainer,
    meta: { app: 'root', code: 'usuario' }
  }, {
    path: 'root/usuario/:mode/:id',
    name: 'AppsRootUsuarioEditor',
    component: AppsRootUsuarioEditor,
    meta: { app: 'root', code: 'usuario' }
  }, {
    path: 'root/feriado',
    name: 'AppsRootFeriadoContainer',
    component: AppsRootFeriadoContainer,
    meta: { app: 'root', code: 'feriado' }
  }, {
    path: 'root/feriado/:mode/:id',
    name: 'AppsRootFeriadoEditor',
    component: AppsRootFeriadoEditor,
    meta: { app: 'root', code: 'feriado' }
  }
]

const AppsCommonModules = [
  {
    path: 'common/switcher',
    name: 'AppsSwitcherContainer',
    component: AppsSwitcherContainer
  }, {
    path: 'common/switcher_old',
    name: 'AppsSwitcherOldContainer',
    component: AppsSwitcherOldContainer
  }
  // , {
  //   path: 'common/switcher/:mode/:id',
  //   name: 'AppsSwitcherEditor',
  //   component: AppsSwitcherEditor
  // }
]

const AppsBycyogaModules = [
  {
    path: 'bycyoga/generar-clases',
    name: 'AppsBycyogaGenerarClases',
    component: AppsBycyogaGenerarClases,
    meta: { app: 'bycyoga', code: 'generar-clases' }
  }, {
    path: 'bycyoga/clase',
    name: 'AppsBycyogaClaseContainer',
    component: AppsBycyogaClaseContainer,
    meta: { app: 'bycyoga', code: 'clase' }
  }, {
    path: 'bycyoga/clase/:mode/:id',
    name: 'AppsBycyogaClaseEditor',
    component: AppsBycyogaClaseEditor,
    meta: { app: 'bycyoga', code: 'clase' }
  }, {
    path: 'bycyoga/clase-instance',
    name: 'AppsBycyogaClaseInstanceContainer',
    component: AppsBycyogaClaseInstanceContainer,
    meta: { app: 'bycyoga', code: 'clase-instance' }
  }, {
    path: 'bycyoga/clase-instance/:mode/:id',
    name: 'AppsBycyogaClaseInstanceEditor',
    component: AppsBycyogaClaseInstanceEditor,
    meta: { app: 'bycyoga', code: 'clase-instance' }
  }, {
    path: 'bycyoga/clase-profesor',
    name: 'AppsBycyogaClaseProfesorContainer',
    component: AppsBycyogaClaseProfesorContainer,
    meta: { app: 'bycyoga', code: 'clase-profesor' }
  }, {
    path: 'bycyoga/clase-profesor/:mode/:id',
    name: 'AppsBycyogaClaseProfesorEditor',
    component: AppsBycyogaClaseProfesorEditor,
    meta: { app: 'bycyoga', code: 'clase-profesor' }
  }, {
    path: 'bycyoga/clase-box',
    name: 'AppsBycyogaClaseBoxContainer',
    component: AppsBycyogaClaseBoxContainer,
    meta: { app: 'bycyoga', code: 'clase-box' }
  }, {
    path: 'bycyoga/clase-box/:mode/:id',
    name: 'AppsBycyogaClaseBoxEditor',
    component: AppsBycyogaClaseBoxEditor,
    meta: { app: 'bycyoga', code: 'clase-box' }
  }, {
    path: 'bycyoga/alumno',
    name: 'AppsBycyogaAlumnoContainer',
    component: AppsBycyogaAlumnoContainer,
    meta: { app: 'bycyoga', code: 'alumno' }
  }, {
    path: 'bycyoga/alumno/:mode/:id',
    name: 'AppsBycyogaAlumnoEditor',
    component: AppsBycyogaAlumnoEditor,
    meta: { app: 'bycyoga', code: 'alumno' }
  }, {
    path: 'bycyoga/alumno-account',
    name: 'AppsBycyogaAlumnoAccountContainer',
    component: AppsBycyogaAlumnoAccountContainer,
    meta: { app: 'bycyoga', code: 'alumno-account' }
  }, {
    path: 'bycyoga/alumno-account/:mode/:id',
    name: 'AppsBycyogaAlumnoAccountEditor',
    component: AppsBycyogaAlumnoAccountEditor,
    meta: { app: 'bycyoga', code: 'alumno-account' }
  }, {
    path: 'bycyoga/alumno-plan',
    name: 'AppsBycyogaAlumnoPlanContainer',
    component: AppsBycyogaAlumnoPlanContainer,
    meta: { app: 'bycyoga', code: 'alumno-plan' }
  }, {
    path: 'bycyoga/alumno-plan/:mode/:id',
    name: 'AppsBycyogaAlumnoPlanEditor',
    component: AppsBycyogaAlumnoPlanEditor,
    meta: { app: 'bycyoga', code: 'alumno-plan' }
  }, {
    path: 'bycyoga/horario',
    name: 'AppsBycyogaHorarioContainer',
    component: AppsBycyogaHorarioContainer,
    meta: { app: 'bycyoga', code: 'horario' }
  }, {
    path: 'bycyoga/horario/:mode/:id',
    name: 'AppsBycyogaHorarioEditor',
    component: AppsBycyogaHorarioEditor,
    meta: { app: 'bycyoga', code: 'horario' }
  }, {
    path: 'bycyoga/dia',
    name: 'AppsBycyogaDiaContainer',
    component: AppsBycyogaDiaContainer,
    meta: { app: 'bycyoga', code: 'dia' }
  }, {
    path: 'bycyoga/dia/:mode/:id',
    name: 'AppsBycyogaDiaEditor',
    component: AppsBycyogaDiaEditor,
    meta: { app: 'bycyoga', code: 'dia' }
  }, {
    path: 'bycyoga/periodo',
    name: 'AppsBycyogaPeriodoContainer',
    component: AppsBycyogaPeriodoContainer,
    meta: { app: 'bycyoga', code: 'periodo' }
  }, {
    path: 'bycyoga/periodo/:mode/:id',
    name: 'AppsBycyogaPeriodoEditor',
    component: AppsBycyogaPeriodoEditor,
    meta: { app: 'bycyoga', code: 'periodo' }
  }, {
    path: 'bycyoga/tarifa',
    name: 'AppsBycyogaTarifaContainer',
    component: AppsBycyogaTarifaContainer,
    meta: { app: 'bycyoga', code: 'tarifa' }
  }, {
    path: 'bycyoga/tarifa/:mode/:id',
    name: 'AppsBycyogaTarifaEditor',
    component: AppsBycyogaTarifaEditor,
    meta: { app: 'bycyoga', code: 'tarifa' }
  }, {
    path: 'bycyoga/transaccion',
    name: 'AppsBycyogaTransaccionContainer',
    component: AppsBycyogaTransaccionContainer,
    meta: { app: 'bycyoga', code: 'transaccion' }
  }, {
    path: 'bycyoga/transaccion/:mode/:id',
    name: 'AppsBycyogaTransaccionEditor',
    component: AppsBycyogaTransaccionEditor,
    meta: { app: 'bycyoga', code: 'transaccion' }
  }, {
    path: 'bycyoga/transaccion-stash',
    name: 'AppsBycyogaTransaccionStashContainer',
    component: AppsBycyogaTransaccionStashContainer,
    meta: { app: 'bycyoga', code: 'transaccion-stash' }
  }, {
    path: 'bycyoga/transaccion-stash/:mode/:id',
    name: 'AppsBycyogaTransaccionStashEditor',
    component: AppsBycyogaTransaccionStashEditor,
    meta: { app: 'bycyoga', code: 'transaccion-stash' }
  }, {
    path: 'bycyoga/transaccion-tipo',
    name: 'AppsBycyogaTransaccionTipoContainer',
    component: AppsBycyogaTransaccionTipoContainer,
    meta: { app: 'bycyoga', code: 'transaccion-tipo' }
  }, {
    path: 'bycyoga/transaccion-tipo/:mode/:id',
    name: 'AppsBycyogaTransaccionTipoEditor',
    component: AppsBycyogaTransaccionTipoEditor,
    meta: { app: 'bycyoga', code: 'transaccion-tipo' }
  }, {
    path: 'bycyoga/box',
    name: 'AppsBycyogaBoxContainer',
    component: AppsBycyogaBoxContainer,
    meta: { app: 'bycyoga', code: 'box' }
  }, {
    path: 'bycyoga/box/:mode/:id',
    name: 'AppsBycyogaBoxEditor',
    component: AppsBycyogaBoxEditor,
    meta: { app: 'bycyoga', code: 'box' }
  }, {
    path: 'bycyoga/plan',
    name: 'AppsBycyogaPlanContainer',
    component: AppsBycyogaPlanContainer,
    meta: { app: 'bycyoga', code: 'plan' }
  }, {
    path: 'bycyoga/plan/:mode/:id',
    name: 'AppsBycyogaPlanEditor',
    component: AppsBycyogaPlanEditor,
    meta: { app: 'bycyoga', code: 'plan' }
  }, {
    path: 'bycyoga/plan-admin',
    name: 'AppsBycyogaPlanAdminContainer',
    component: AppsBycyogaPlanAdminContainer,
    meta: { app: 'bycyoga', code: 'planAdmin' }
  }, {
    path: 'bycyoga/plan-admin/:mode/:id',
    name: 'AppsBycyogaPlanAdminEditor',
    component: AppsBycyogaPlanAdminEditor,
    meta: { app: 'bycyoga', code: 'lanAdmin' }
  }, {
    path: 'bycyoga/report',
    name: 'AppsBycyogaReport',
    component: AppsBycyogaReport,
    meta: { app: 'bycyoga', code: 'report' }
  }, {
    path: 'bycyoga/report-full',
    name: 'AppsBycyogaReportFull',
    component: AppsBycyogaReportFull,
    meta: { app: 'bycyoga', code: 'report-full' }
  }
]

const AppsMotionModules = [
  {
    path: 'motion/alumno',
    name: 'AppsMotionAlumnoContainer',
    component: AppsMotionAlumnoContainer,
    meta: { app: 'motion', code: 'alumno' }
  }, {
    path: 'motion/alumno/:mode/:id',
    name: 'AppsMotionAlumnoEditor',
    component: AppsMotionAlumnoEditor,
    meta: { app: 'motion', code: 'alumno' }
  }, {
    path: 'motion/profesor',
    name: 'AppsMotionProfesorContainer',
    component: AppsMotionProfesorContainer,
    meta: { app: 'motion', code: 'Profesor' }
  }, {
    path: 'motion/profesor/:mode/:id',
    name: 'AppsMotionProfesorEditor',
    component: AppsMotionProfesorEditor,
    meta: { app: 'motion', code: 'Profesor' }
  }, {
    path: 'motion/plan',
    name: 'AppsMotionPlanContainer',
    component: AppsMotionPlanContainer,
    meta: { app: 'motion', code: 'Plan' }
  }, {
    path: 'motion/plan/:mode/:id',
    name: 'AppsMotionPlanEditor',
    component: AppsMotionPlanEditor,
    meta: { app: 'motion', code: 'Plan' }
  }, {
    path: 'motion/bloque-general',
    name: 'AppsMotionBloqueGeneralContainer',
    component: AppsMotionBloqueGeneralContainer,
    meta: { app: 'motion', code: 'BloqueGeneral' }
  }, {
    path: 'motion/bloque-general/:mode/:id',
    name: 'AppsMotionBloqueGeneralEditor',
    component: AppsMotionBloqueGeneralEditor,
    meta: { app: 'motion', code: 'BloqueGeneral' }
  }, {
    path: 'motion/bloque-alumno-profesor',
    name: 'AppsMotionBloqueAlumnoProfesorContainer',
    component: AppsMotionBloqueAlumnoProfesorContainer,
    meta: { app: 'motion', code: 'BloqueAlumnoProfesor' }
  }, {
    path: 'motion/bloque-alumno-profesor/:mode/:id',
    name: 'AppsMotionBloqueAlumnoProfesorEditor',
    component: AppsMotionBloqueAlumnoProfesorEditor,
    meta: { app: 'motion', code: 'BloqueAlumnoProfesor' }
  }, {
    path: 'motion/via',
    name: 'AppsMotionViaContainer',
    component: AppsMotionViaContainer,
    meta: { app: 'motion', code: 'Via' }
  }, {
    path: 'motion/via/:mode/:id',
    name: 'AppsMotionViaEditor',
    component: AppsMotionViaEditor,
    meta: { app: 'motion', code: 'Via' }
  }, {
    path: 'motion/ops',
    name: 'AppsMotionOps',
    component: AppsMotionOps,
    meta: { app: 'motion', code: 'Ops' }
  }, {
    path: 'motion/reportes',
    name: 'AppsMotionReports',
    component: AppsMotionReports,
    meta: { app: 'motion', code: 'AppsMotionReports' }
  }, {
    path: 'motion/live',
    name: 'AppsMotionLive',
    component: AppsMotionLive,
    meta: { app: 'motion', code: 'AppsMotionLive' }
  }
]

const AppsHydModules = [
  {
    path: 'hyd/rut',
    name: 'AppsHydRutContainer',
    component: AppsHydRutContainer,
    meta: { app: 'hyd', code: 'rut' }
  }, {
    path: 'hyd/rut/:mode/:id',
    name: 'AppsHydRutEditor',
    component: AppsHydRutEditor,
    meta: { app: 'hyd', code: 'rut' }
  }
]

const AppsLinksModules = [
  {
    path: 'links/link',
    name: 'AppsLinksLinkContainer',
    component: AppsLinksLinkContainer,
    meta: { app: 'links', code: 'link' }
  }, {
    path: 'links/link/:mode/:id',
    name: 'AppsLinksLinkEditor',
    component: AppsLinksLinkEditor,
    meta: { app: 'links', code: 'link' }
  }
]

const AppsDemoModules = [
  {
    path: 'demos/demo',
    name: 'AppsDemosDemoContainer',
    component: AppsDemosDemoContainer,
    meta: { app: 'demos', code: 'demo' }
  }, {
    path: 'demos/demo/:mode/:id',
    name: 'AppsDemosDemoEditor',
    component: AppsDemosDemoEditor,
    meta: { app: 'demos', code: 'demo' }
  }, {
    path: 'demos/demo2',
    name: 'AppsDemosDemo2Container',
    component: AppsDemosDemo2Container,
    meta: { app: 'demos', code: 'demo2' }
  }, {
    path: 'demos/demo3',
    name: 'AppsDemosDemo3Container',
    component: AppsDemosDemo3Container,
    meta: { app: 'demos', code: 'demo3' }
  }
]

const appsModules = []
  .concat(AppsBycyogaModules)
  .concat(AppsMotionModules)
  .concat(AppsHydModules)
  .concat(AppsLinksModules)
  .concat(AppsDemoModules)
  .concat(AppsCommonModules)
  .concat(AppsRootModules)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: authGuard
  }, {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { title: 'login' }
  }, {
    path: '/loginOauth',
    name: 'loginOauth',
    component: LoginOauth,
    meta: { title: 'login' }
  }, {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: { title: 'logout' }
  }, {
    path: '/apps',
    name: 'Apps',
    component: DashboardLayout,
    children: appsModules,
    beforeEnter: authGuard
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const DEFAULT_TITLE = 'wawentu'
router.afterEach((to, from) => {
  document.title = DEFAULT_TITLE
  if (to.meta && to.meta.title) {
    document.title += ' - ' + to.meta.title
  }
  if (to.meta && to.meta.code) {
    document.title += ' - ' + to.meta.code
  }
  store.commit('setRoute', document.title)
})

export default router
