import axios from 'axios'
import qs from 'qs'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'

// @deprecated
const verifyLogin = async (storeObject, data) => {}

const storeLoginData = async (storeObject, jwt) => {
  if (window) {
    window.localStorage.setItem('jwt', jwt)
    storeObject.commit('setJwt', jwt)
    try {
      const decoded = jwt_decode(jwt)
      // save expiration
      window.localStorage.setItem('jwtExp', decoded.exp * 1000)
      this.$store.commit('setJwtExp', decoded.exp * 1000)
    } catch (e) { }
  }
}

const doLogin = async (storeObject, data) => {
  try {
    const loginResult = await axios.post(process.env.VUE_APP_API_SERVER + '/authentication', data)
    if (loginResult && loginResult.data && loginResult.data.accessToken) {
      const jwt = loginResult.data.accessToken
      storeLoginData(storeObject, jwt)
      return { result: true, jwt }
    } else {
      return { result: false }
    }
  } catch (e) {
    return { result: false, reason: e }
  }
}

const doUserData = async (storeObject, data) => {
  try {
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    const createResult = await axios.get(process.env.VUE_APP_API_SERVER + '/users-data', {})
    storeObject.commit('setUserData', createResult.data.data[0])
    return createResult
  } catch (e) {
    console.log('>>> got here?', e)
  }
}

const create = async (storeObject, data) => {
  const { model, payload } = data
  // borrar cosas antes
  delete payload.computed
  delete payload.joined
  try {
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    const createResult = axios.post(process.env.VUE_APP_API_SERVER + '/' + model, payload)
    return createResult
  } catch (e) {}
}

const get = async (storeObject, data) => {
  const { model, id } = data
  try {
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    return axios.get(process.env.VUE_APP_API_SERVER + '/' + model + '/' + id)
  } catch (e) {}
}

const remove = async (storeObject, data) => {
  const { model, id } = data
  try {
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    return axios.delete(process.env.VUE_APP_API_SERVER + '/' + model + '/' + id)
  } catch (e) {}
}

const find = async (storeObject, data) => {
  const { model, query } = data
  try {
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    let findResult = await axios.get(process.env.VUE_APP_API_SERVER + '/' + model, {
      params: query,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
    if (findResult && findResult.data) findResult = findResult.data // fix para axios

    return findResult
  } catch (e) {}
}

const update = async (storeObject, data) => {
  const { model, payload, id } = data
  // borrar cosas antes
  delete payload.computed
  delete payload.joined
  axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
  return axios.put(process.env.VUE_APP_API_SERVER + '/' + model + '/' + id, payload) // update
}

// motion
const appsMotion = async (storeObject, data) => {
  const { payload } = data
  const { action } = payload
  let service = ''
  axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
  if (action === 'generarAgendaDiaria') {
    service = 'apps-motion-service-generar-agenda-diaria'
    return axios.put(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  } else if (action === 'asignarProfesores') {
    service = 'apps-motion-service-asignar-profesores'
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    return axios.put(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  } else if (action === 'generarReporte') {
    service = 'apps-motion-service-generar-reporte'
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    return axios.put(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  } else if (action === 'marcarPresente') {
    service = 'apps-motion-service-marcar-presente'
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    return axios.put(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  }
}

// para appsBycyoga
const proxy = async (storeObject, data) => {
  const { payload } = data
  const { action } = payload
  let service = ''
  axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
  if (action === 'commitTransaction') {
    service = 'apps-bycyoga-service-commit-transaction-stash'
    return axios.put(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  } else if (action === 'deleteClassInstance') {
    service = 'apps-bycyoga-service-delete-class-instance'
    return axios.put(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  } else if (action === 'getAlumnos' || action === 'getAlumnosZoom' || action === 'getAlumnosPleno') {
    service = 'apps-bycyoga-service-clase-alumnos-get'
    return axios.put(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  } else if (action === 'getAlumnoToken') {
    service = 'apps-bycyoga-service-clase-alumnos-token'
    return axios.put(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  } else if (action === 'getClases') {
    service = 'apps-bycyoga-service-alumno-clases-get'
    return axios.put(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  } else if (action === 'generarClases') {
    service = 'apps-bycyoga-service-generar-clases'
    return axios.get(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  } else if (action === 'marcarPresente') {
    service = 'apps-bycyoga-service-marcar-presente'
    return axios.put(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  } else if (action === 'setExpirationPlusTen') {
    service = 'apps-bycyoga-service-expiration-plus-ten'
    return axios.put(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  } else if (action === 'sysServiceGetImages') {
    service = 'sys-service-get-images'
    return axios.post(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  } else if (action === 'setPresenteAlumno') {
    service = 'apps-bycyoga-service-set-presente-alumno'
    return axios.post(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  }
}

const report = async (storeObject, data) => {
  const { payload } = data
  const { report, casoFull, fechaInicio } = payload
  // const service = 'apps-bycyoga-service-commit-transaction-stash'
  const reportesValidos = ['ventas', 'alumnos', 'listadoDeAlumnos', 'asistencia', 'listadoAsistenciaPleno', 'reporteVencimientosPlanPleno']
  if (reportesValidos.indexOf(report) !== -1) {
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    const response = await axios({
      method: 'put',
      url: process.env.VUE_APP_API_SERVER + '/apps-bycyoga-service-generate-report',
      data: { report, casoFull, fechaInicio },
      responseType: 'blob'
    })
    // @ref https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743#file-download-file-js
    // @ref https://jetrockets.com/blog/l8dadq8oac-how-to-download-files-with-axios
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `Reporte_${report}.xlsx`)
    document.body.appendChild(link)
    link.click()
  }
}

export {
  verifyLogin,
  doLogin,
  doUserData,
  find,
  get,
  create,
  remove,
  update,
  storeLoginData,
  proxy,
  appsMotion,
  report
}
