import { createStore } from 'vuex'
import {
  doLogin,
  doUserData,
  find,
  get,
  create,
  remove,
  update,
  proxy,
  appsMotion,
  report
} from './api.js'

/* eslint-disable no-useless-constructor */
export default createStore({
  state: {
    jwt: '',
    jwtExp: Date.now() - 1,
    code: 'the code goes here',
    count: 0,
    references: [],
    temp: 'the id',
    messageQueue: [],
    loading: false,
    theme: 'theme',
    payload: {},
    route: {},
    userData: {},
    menuStatus: true
  },

  mutations: {
    setJwt (state, newValue) {
      state.jwt = newValue
    },
    setJwtExp (state, newValue) {
      state.jwtExp = newValue
    },
    setCode (state, newValue) {
      state.code = newValue
    },
    increment (state) {
      state.count++
    },
    addReference (state, newValue) {
      state.references.push(newValue)
    },
    setTemp (state, newValue) {
      state.temp = newValue
    },
    setPayload (state, newValue) {
      state.payload = newValue
    },
    setRoute (state, newValue) {
      state.route = newValue
    },
    setLoading (state, newValue) {
      state.loading = newValue
    },
    setUserData (state, newValue) {
      state.userData = newValue
    },
    setMenuStatus (state, newValue) {
      state.menuStatus = newValue
    }

  },

  actions: {
    doLogin,
    doUserData,
    find,
    get,
    create,
    remove,
    update,
    proxy,
    appsMotion,
    report
  },

  modules: {},

  getters: {
    getJwt: state => state.jwt,
    getJwtExp: state => state.jwt,
    getCode: state => state.code,
    count: state => state.count,
    getReferences: state => state.references,
    getTemp: state => state.temp,
    getPayload: state => state.payload,
    getRoute: state => state.route,
    getLoading: state => state.loading,
    getUserData: state => state.userData,
    getMenuStatus: state => state.menuStatus
  }
})
